/** @jsxImportSource theme-ui */

import { graphql, Link, PageProps } from "gatsby";
import { BaseStyles } from "theme-ui";
import ExternalLink from "../components/external-link";
import Global from "../components/global";
import { Header } from "../components/header";
import HistoryList from "../components/history-list";
import Main from "../components/main";
import Seo from "../components/seo";
import ThisNendoTable from "../components/this-nendo-table";
import Wrapper from "../components/wrapper";
import { getNendo } from "../utils/datetimeutils";
import { createTaikaiPath } from "../utils/linkutils";

const TITLE = "獅子舞大会の一覧";

export const Head = () => <Seo title={TITLE} article={true} isShishimai={true} />;

const Shishimai = ({ data }: PageProps<Queries.ShishimaiQuery>) => {
  const HISTORY_LIST_MAX_NUM = 15;
  const now = new Date();

  const taikaiList = data.allGoogleSmetaSheet.edges.filter(({ node }) => {
    return node.taikaiId && node.nameOfficial;
  });

  const entryList = data.allGoogleSdataSheet.edges.filter(({ node }) => {
    return node.taikaiId && node.name;
  });

  return (
    <>
      <Global />
      <Header />
      <Main>
        <Wrapper>
          <BaseStyles>
            <h1>{TITLE}</h1>
            <p>マニアックゾーンへようこそ。</p>
            <p>
              こちらのページでは、全国の催しの中から獅子舞オンリーの大会・発表会の日程をピックアップしました。<br />
              最初から最後まで全部獅子舞、左を向いても右を向いても獅子舞、などといった、まさに<br />
              <span style={{ fontWeight: "bold" }}>獅子舞好きの獅子舞好きによる獅子舞好きのためのイベント</span>{" "}
              といっても過言ではないでしょう。（注：過言です）
            </p>
            <p>みんなで獅子舞を見に行こうぜ！</p>

            <h2>更新履歴</h2>
            <HistoryList data={data.allGoogleShistorySheet.edges} maxItems={HISTORY_LIST_MAX_NUM} isAnchorLink={false} isShishimai={true} />

            <h2>{getNendo(now)}の日程一覧</h2>
            <p>名前をタップすると詳細ページに移動します。</p>
            <ThisNendoTable metadata={taikaiList} data={entryList} today={now} isAnchorLink={false} />

            <h2>注意事項</h2>
            <div sx={{ marginBottom: "3.0rem" }}>
              <ul sx={list}>
                <li>
                  <b>このサイトに掲載されている内容は間違っているかも知れません。</b>
                  また、悪天候などの理由で中止になることもありますので、お出かけの際は主催者に内容をご確認下さい。
                </li>
                <li>古い情報はリンク切れになっているかも知れませんが、どうしようもないのでご了承下さい。</li>
                <li>
                  定期的に開催される催しのみを掲載しています。（過去にまだ第1回しか開催されていないものについては、期待しつつ様子見中。）また、個人的趣味で虎舞も含めましたが気にしないでください。
                </li>
              </ul>
            </div>

            <h2>番外編</h2>
            <p>獅子舞オンリーというわけではないけれど、“シシ分”（獅子舞成分）が豊富に含まれているイベントやお祭りを独断と偏見で紹介。</p>

            <h3 sx={subtitle}>九十九里町郷土芸能大会</h3>
            <div>
              <p>
                ※ 日程については <Link to={createTaikaiPath("kujukurimachi_kyodo")}>九十九里町郷土芸能大会</Link> のページを参照
              </p>
              <p>
                3年に1度の開催。2017年に見学に行ったのですが、9団体のうち7団体が獅子舞・羯鼓舞という密度の濃い内容でした。土日の2日間に渡って開催されるのも素晴らしい。（どちらの日も同じ団体が出演されますが、演目が異なるようです。）<br />
                アクセスは千葉駅から高速バス（九十九里ライナー）で「九十九里町役場」下車が便利だと思います。駐車場あり。
              </p>
              <p>
                2017年の動画はこちら →{" "}
                <ExternalLink url="https://myshishilist.wordpress.com/2017/10/22/2017-10-22_kujukuri/">
                  第9回九十九里町郷土芸能大会 （2017年10月22日）
                </ExternalLink>
              </p>
            </div>

            <h3 sx={subtitle}>香取市里神楽演舞会</h3>
            <div>
              <p>
                ※ 日程については <Link to={createTaikaiPath("katorishi_kagura")}>香取市里神楽演舞会</Link> のページを参照
              </p>
              <p>
                2年に1度の開催。これまで2012年と2018年に見学に行きました。<br />
                神楽の公演なので獅子舞とは限らないのですが、2018年のプログラムは7団体のうち4団体が神楽獅子、1団体が三匹獅子舞と、こちらも密度が濃かったです。<br />
                会場は年によって異なるようですので注意。
              </p>
              <p>
                2018年の動画（一部）はこちら →{" "}
                <ExternalLink url="https://myshishilist.wordpress.com/2018/03/11/2018-03-11_katorishi_satokagura/">
                  第五回香取市里神楽演舞会 （2018年3月11日）
                </ExternalLink>
              </p>
            </div>

            <h3 sx={subtitle}>常陸國總社宮例大祭（石岡のおまつり）</h3>
            <div>
              <p>
                毎年9月中旬の3連休に開催されます。たいていのお祭りが1～2日のところ、3日間ですよ！<br />
                多くの山車にまじってたくさんの「幌獅子」が町を練り歩きます。Wikipediaによると32台（…ってそんなにたくさんいたかなあ）。<br />
                茨城県内で幌獅子が出る祭礼は他にもありますが、これだけ大規模なのは石岡のみではないでしょうか。<br />
                場所はJR石岡駅周辺。駐車場あり。
              </p>
            </div>

            <h3 sx={subtitle}>獅子たちの里　三木まんで願。</h3>
            <div>
              <p>
                「獅子たちの里」こと香川県三木町の秋大祭。<br />
                残念ながらまだ見学に行ったことはありませんが、写真を見る限り多くの獅子舞が参加するようです。<br />
                三木町の公式Webサイトに情報が掲載されるようなので、気になる方は定期的にチェックすべし。<br />
                ついでに少しやりすぎ(?!)な <ExternalLink url="https://www.youtube.com/watch?v=3FCdE-3LmUc">町公式PV</ExternalLink> もチェックすべし。
              </p>
            </div>

            <h3 sx={subtitle}>ひみまつり</h3>
            <div>
              <p>
                富山県氷見市にて8月上旬に開催される花火大会＋各種イベント。<br />
                夕方に「獅子舞フェスティバル」があり、市内外あわせて3～４団体が演舞を披露されるようです。<br />
                市外からも参加があるのはすごい。ポスターにも毎年ちゃんと獅子舞のイラストが入っていますね :-)
              </p>
            </div>

            <h3 sx={subtitle}>富山新港花火大会</h3>
            <div>
              <p>
                7月下旬に富山県射水市にて行われる花火大会。こちらも打ち上げの前に2時間ほど獅子舞の演舞があるようです。<br />
                獅子舞と花火が1日に同時に見られておトクな感じ。<br />
                ちなみに平成28年までは「射水市獅子舞競演会」として花火大会とは別に開催されていたようです。
              </p>
            </div>

            <h3 sx={subtitle}>善通寺空海まつり</h3>
            <div>
              <p>
                善通寺（香川県善通寺市）にて毎年11月3日に行われる「佐伯祖廟御法楽法要」とその関連行事。<br />
                行事の1つに「獅子舞大会」があり、市内約40の獅子組が舞を奉納するそうです。<br />
                1つの寺院に40組って…すごい数だな。ネット上の写真を見る限り一斉演舞なのでしょうか。
              </p>
            </div>
          </BaseStyles>
        </Wrapper>
      </Main>
    </>
  );
};

export default Shishimai;

const list = {
  listStyleType: "disc",

  "li": {
    marginTop: "0.5rem",
    marginLeft: "1.5rem",
  },
};

const subtitle = {
  borderBottom: "1px solid #333333",
};

export const query = graphql`
  query Shishimai {
    allGoogleSmetaSheet(sort: {fields: date, order: ASC}, filter: {nameOfficial: {regex: "/^[^#?]/"}}) {
      edges {
        node {
          taikaiId
          namePrefix
          nameOfficial
          nameSuffix
          myNameSuffixDefault
          date
        }
      }
    }
    allGoogleSdataSheet(filter: {name: {regex: "/^[^#?]/"}}) {
      edges {
        node {
          taikaiId
          namePrefix
          name
          nameSuffix
          myNameSuffix
          canceled
          date
          isFest
          endDate
          isEndDateFest
          openTime
          startTime
          endTime
          locationName
          locationSubName
          note
          sources
        }
      }
    }
    allGoogleShistorySheet {
      edges {
        node {
          updateDate
          isNew
          taikaiId
          title
          startDate
          endDate
        }
      }
    }
  }
`;
